export default {
  write_message: 'Nachricht hier eingeben...',
  you: 'Sie',
  timeago: {
    now: 'Jetzt',
    minutes: 'vor {n} Minute | vor {n} Minuten',
    hours: 'vor {n} Stunde | vor {n} Stunden',
    days: 'vor {n} Tag | vor {n} Tagen'
  }
};
