export default {
  write_message: 'Write a message...',
  you: 'You',
  timeago: {
    now: 'just now',
    minutes: '{n} minute ago | {n} minutes ago',
    hours: '{n} hour ago | {n} hours ago',
    days: '{n} day ago | {n} days ago'
  }
};
